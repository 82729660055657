import { i18n } from 'config/i18n'
import { RouteType } from 'interfaces/router.interfaces'

export const parametersProductsAndServices = (): RouteType[] => [
  {
    id: 10,
    name: i18n.get('pro.products.productCatalog'),
    navigate: {
      to: '/products',
    },
  },
  {
    id: 11,
    name: i18n.get('pro.products.productCategories'),
    navigate: {
      to: '/categories',
    },
  },
]
