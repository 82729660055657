export const keySetsRu = [
  () => import('./common.json'),
  () => import('./apiErrors.json'),
  () => import('./auth.json'),
  () => import('./pro.auth.json'),
  () => import('./menubar.json'),
  () => import('./products.json'),
  () => import('./cities.json'),
  () => import('./attributeTypes.json'),
  () => import('./attributeValues.json'),
  () => import('./users.json'),
  () => import('./pro.companySetting.json'),
  () => import('./shops.json'),
  () => import('./pro.roles.json'),
  () => import('./manuals.json'),
  () => import('./pro.employee.json'),
  () => import('./validationErrors.json'),
  () => import('./pro.products.json'),
  () => import('./pro.categories.json'),
  () => import('./pro.receipts.json'),
  () => import('./seoTexts.json'),
  () => import('./pro.contractors.json'),
]
