import { AttributeTypeFormData, AttributeValueFormData } from 'admin/attributes/api/interface'
import { CitiesFormData } from 'admin/cities/api'
import { ProductFormData } from 'admin/products/api'
import { UsersFormData } from 'admin/users/api'
import { i18n } from 'config/i18n'
import { ImageData } from 'interfaces/common.interfaces'
import * as yup from 'yup'

const imageSchema: yup.ObjectSchema<ImageData> = yup.object().shape({
  file: yup.mixed(),
  id: yup.mixed<string | number>().required(),
  url: yup.string(),
})

export const productSchema: yup.ObjectSchema<ProductFormData> = yup.object().shape({
  name: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  price: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .positive(i18n.get('validationErrors.pricePositiveNumberError'))
    .required(() => i18n.get('validationErrors.requiredError'))
    .integer(i18n.get('validationErrors.integerNumberError')),
  discountPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .positive(i18n.get('validationErrors.pricePositiveNumberError'))
    .required(() => i18n.get('validationErrors.requiredError'))
    .integer(i18n.get('validationErrors.integerNumberError')),
  isActive: yup.boolean().required(),
  isFinished: yup.boolean().required(),
  description: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  categoryIds: yup
    .array()
    .of(yup.number().positive().integer().required())
    .min(1, i18n.get('validationErrors.categoryMinError'))
    .required(),
  media: yup.object().shape({
    images: yup.array().of(imageSchema).required(),
  }),
  attributeValueIds: yup.object(),
  shopId: yup.number(),
  composition: yup.string().defined(),
})

export const attributeTypeSchema: yup.ObjectSchema<AttributeTypeFormData> = yup.object().shape({
  name: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  slug: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  order: yup.number().required(() => i18n.get('validationErrors.requiredError')),
})

export const attributeValueSchema: yup.ObjectSchema<AttributeValueFormData> = yup.object().shape({
  slug: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  value: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  titleValue: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  order: yup.number().required(),
})

export const citySchema: yup.ObjectSchema<CitiesFormData> = yup.object().shape({
  name: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  nameInDat: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  nameInPrep: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  slug: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  showInFooter: yup.boolean().required(),
})

export const userSchema: yup.ObjectSchema<UsersFormData> = yup.object().shape({
  name: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  email: yup
    .string()
    .email(i18n.get('validationErrors.invalidEmailAddress'))
    .required(() => i18n.get('validationErrors.requiredError')),
  password: yup.string(),
  phone: yup.string().required(() => i18n.get('validationErrors.requiredError')),
  role: yup.object().shape({
    id: yup.number().required(),
    name: yup.string(),
  }),
})
