import { axios } from 'config/axios'
import { GetAllData, GetAllResponse, Pagination } from 'interfaces/api.interfaces'
import { deleteEmpty } from 'packages/helper'
import { defaultPagination } from 'utils/pagination'

import { proUserEndpoints } from './endpoints'
import { Employee, EmployeeFormData, EmployeeRequestUpdate } from './interfaces'

const getUsers = async (
  pagination: Pagination,
  companyId: string,
  filterName?: string,
  filterActive?: boolean,
): GetAllResponse<Employee> => {
  const { data } = await axios.request<GetAllData<Employee>>({
    method: 'GET',
    url: proUserEndpoints.getUsers(),
    params: deleteEmpty({
      ...defaultPagination(pagination, 50),
      filter: { name: filterName, active: filterActive },
      with: { 0: 'companies', 1: 'salePoints', 2: 'roles', 3: 'permissions' },
    }),
    headers: {
      'Company-Id': companyId,
    },
  })
  return data
}

const getUser = async (userId: string, companyId: string): Promise<Employee> => {
  const { data } = await axios.request<Employee>({
    method: 'GET',
    url: proUserEndpoints.getUser(userId),
    params: deleteEmpty({ with: { 0: 'companies', 1: 'salePoints', 2: 'permissions', 3: 'roles' } }),
    headers: {
      'Company-Id': companyId,
    },
  })
  return data
}

const getCurrentUser = async (companyId: string): Promise<Employee> => {
  const { data } = await axios.request<Employee>({
    method: 'GET',
    url: proUserEndpoints.getCurrentUser(),
    params: deleteEmpty({ with: { 0: 'companies', 1: 'salePoints', 2: 'permissions', 3: 'roles' } }),
    headers: {
      'Company-Id': companyId,
    },
  })
  return data
}

const verifyPhone = async (phone: string): Promise<Partial<Employee>> => {
  const { data } = await axios.request<Partial<Employee>>({
    method: 'GET',
    url: proUserEndpoints.verifyPhone(phone),
  })

  return data
}

const updateUser = async (userId: string, form: EmployeeRequestUpdate, companyId: string): Promise<any> => {
  const { data } = await axios.request<Employee>({
    method: 'PUT',
    url: proUserEndpoints.editUser(userId),
    data: form,
    headers: {
      'Company-Id': companyId,
    },
  })

  return data
}

const activateUser = async (ids: string[], companyId: string) => {
  await axios.request<Promise<void>>({
    method: 'POST',
    url: proUserEndpoints.activateUser(),
    data: { ids: [...ids] },
    headers: {
      'Company-Id': companyId,
    },
  })
}

const deactivateUser = async (ids: string[], companyId: string) => {
  await axios.request<Promise<void>>({
    method: 'POST',
    url: proUserEndpoints.deactivateUser(),
    data: { ids: [...ids] },
    headers: {
      'Company-Id': companyId,
    },
  })
}

const deleteUser = async (id: string, companyId: string) => {
  await axios.request<Promise<void>>({
    method: 'DELETE',
    url: proUserEndpoints.deleteUser(id),
    headers: {
      'Company-Id': companyId,
    },
  })
}

const createUser = async (form: EmployeeFormData, companyId: string): Promise<Employee> => {
  const { data } = await axios.request<Employee>({
    method: 'POST',
    url: proUserEndpoints.createUser(),
    data: form,
    params: { with: { 0: 'salePoints', 1: 'roles' } },
    headers: {
      'Company-Id': companyId,
    },
  })

  return data
}

const resendInviteToUser = async (userId: string, companyId: string) => {
  await axios.request<void>({
    method: 'POST',
    url: proUserEndpoints.resendInviteUser(userId, companyId),
  })
}

const revokeInviteToUser = async (userId: string, companyId: string) => {
  await axios.request<void>({
    method: 'POST',
    url: proUserEndpoints.revokeInviteUser(userId),
    headers: {
      'Company-Id': companyId,
    },
  })
}

const inviteExistUser = async (userId: string, companyId: string, body: EmployeeFormData) => {
  await axios.request<void>({
    method: 'POST',
    url: proUserEndpoints.inviteExistUser(userId),
    headers: {
      'Company-Id': companyId,
    },
    data: body,
  })
}

export const proListEmployeesApi = {
  getUsers,
  activateUser,
  deactivateUser,
  getUser,
  updateUser,
  getCurrentUser,
  createUser,
  resendInviteToUser,
  revokeInviteToUser,
  verifyPhone,
  inviteExistUser,
  deleteUser,
}
