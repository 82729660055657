import { i18n } from 'config/i18n'
import { RouteType } from 'interfaces/router.interfaces'

export const parametersCompanyAndSetting = (): RouteType[] => [
  {
    id: 30,
    name: i18n.get('pro.employee.table.pointsSale'),
    navigate: {
      to: '/shops',
    },
  },
  {
    id: 31,
    name: i18n.get('pro.companySetting.settingCompany'),
    navigate: {
      to: '/settings-company',
    },
  },
  {
    id: 32,
    name: i18n.get('pro.employee.listEmployee'),
    navigate: {
      to: '/employees',
    },
  },
  {
    id: 33,
    name: i18n.get('pro.roles.rolesAndAccessRight'),
    navigate: {
      to: '/roles',
    },
  },
  {
    id: 34,
    name: i18n.get('manuals.title'),
    navigate: {
      to: '/manuals',
    },
  },
]
