import { FC, useEffect, useMemo, useState } from 'react'

import { useRouterState } from '@tanstack/react-router'
import Cart from 'assets/images/cart.svg?react'
import Storehouse from 'assets/images/storehouse.svg?react'
import { MenuItemListPro } from 'components/template/Sidebar/MenuItemListPro'
import { useMenuList } from 'components/template/Sidebar/store'
import { useTranslate } from 'config/i18n'
import { parametersCompanyAndSetting } from 'constants/parametersCompanyAndSetting'
import { parametersProductsAndServices } from 'constants/parametersProductsAndServices'
import { parametersStorehouse } from 'constants/parametersStorehouse'
import { CompanySuggestion } from 'pro/companies/api/interfaces'
import { CardCompany } from 'pro/companies/components/CardCompany'
import { useGetOneCompanyInfo } from 'pro/companies/queries'
import { useCompanyId } from 'pro/companies/store'

import { getNewSelectedParameter } from './helpers/getNewSelectedParameter'
import { ItemsType } from './interfaces/common'
import classes from './MenuMarketPro.module.scss'

export const MenuMarketPro: FC = () => {
  const {
    location: { pathname },
  } = useRouterState()
  const { companyId } = useCompanyId()
  const { oneCompanyInfo, isLoading } = useGetOneCompanyInfo(companyId)
  const [company, setCompany] = useState<CompanySuggestion>()
  const [selectedParameter, setSelectedParameter] = useState<number | null>(null)
  const translate = useTranslate()
  const cart = useMenuList((state) => state.openedList.cart)
  const storeHouse = useMenuList((state) => state.openedList.storeHouse)
  const settings = useMenuList((state) => state.openedList.settings)
  const toggle = useMenuList((state) => state.toggle)

  const onParameterClick = (item: number | null) => {
    setSelectedParameter(item)
  }

  const items: ItemsType = useMemo(
    () => ({
      cart: parametersProductsAndServices(),
      storeHouse: parametersStorehouse(),
      settings: parametersCompanyAndSetting(),
    }),
    [],
  )

  useEffect(() => {
    if (selectedParameter === null) {
      const newSelectedParameter = getNewSelectedParameter(items, pathname)
      onParameterClick(newSelectedParameter)
    }
  }, [pathname, selectedParameter, items])

  useEffect(() => {
    setCompany(oneCompanyInfo)
  }, [oneCompanyInfo])

  return (
    <ul className={classes.menu}>
      <CardCompany company={company} isLoading={isLoading} />
      <MenuItemListPro
        icon={<Cart />}
        items={items.cart}
        onParameterClick={onParameterClick}
        openItems={cart}
        selectedParameter={selectedParameter}
        setOpenItems={() => toggle('cart')}
        title={translate('pro.products.productsAndServices')}
      />
      <MenuItemListPro
        icon={<Storehouse />}
        items={items.storeHouse}
        onParameterClick={onParameterClick}
        openItems={storeHouse}
        selectedParameter={selectedParameter}
        setOpenItems={() => toggle('storeHouse')}
        title={translate('pro.receipts.storehouse')}
      />
      <MenuItemListPro
        items={items.settings}
        onParameterClick={onParameterClick}
        openItems={settings}
        selectedParameter={selectedParameter}
        setOpenItems={() => toggle('settings')}
        title={translate('pro.companySetting.companyAndSettings')}
      />
    </ul>
  )
}
