import { i18n } from 'config/i18n'
import { RouteType } from 'interfaces/router.interfaces'

export const parametersStorehouse = (): RouteType[] => [
  {
    id: 21,
    name: i18n.get('pro.receipts.title'),
    navigate: {
      to: '/receipts',
    },
  },
  {
    id: 22,
    name: i18n.get('pro.contractors.title'),
    navigate: {
      to: '/contractors',
    },
  },
]
